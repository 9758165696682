<!--
 * @Author: zhaoyang
 * @Date: 2024-03-21 13:05:22
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-03-24 19:38:00
-->

<template>
    <picture>
        <source
            :srcset="webp"
            type="image/webp"
        >
        <slot>
            <img
                :src="fallback"
                :class="customClass"
                :style="customStyle"
                v-bind="$attrs"
                alt=""
            >
        </slot>
    </picture>
</template>

<script>
export default {
    name: 'EcImage',

    props: {
        webp: {
            type: String,
            default: ''
        },

        fallback: {
            type: String,
            default: ''
        },

        customClass: {
            type: [String, Object, Array],
            default: () => ({})
        },

        customStyle: {
            type: [Object, String],
            default: () => ({})
        }
    }
};
</script>
