var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "picture",
    [
      _c("source", { attrs: { srcset: _vm.webp, type: "image/webp" } }),
      _vm._v(" "),
      _vm._t("default", function () {
        return [
          _c(
            "img",
            _vm._b(
              {
                class: _vm.customClass,
                style: _vm.customStyle,
                attrs: { src: _vm.fallback, alt: "" },
              },
              "img",
              _vm.$attrs,
              false
            )
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }